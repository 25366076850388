<template>
  <div class="address-page">
    <template v-if="manageAddressList.length">
      <div class="addressItemDiv">
        <div class="addressItem"
        v-for="(item, index) in manageAddressList"
        :key="index">
          <div class="addressContent" @click="jumpUrl(item)">
            <div class="line1">
              <div class="name">{{ item.receiptname }}</div>
              <div class="tel">{{ item.call }}</div>
            </div>
            <div class="line2" v-html="addressAction(item)"></div>
          </div>
          <div class="operate">
            <div class="delete" @click="addressBehavior(item, 'delete')">
              <div class="deleteBefore">
                <img src='./img/delete.png'/>
              </div>
              <span>删除</span>
            </div>
            <div class="edit" @click="addressBehavior(item, 'edit')">
              <div class="editBefore">
                <img src="./img/edit.png" />
              </div>
              <span>编辑</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="hasNoPrize" v-show="!manageAddressList.length">
      <div class="noPrizeImg">
        <img src="./img/noPrizeImg.png">
      </div>
      <div class="noPrizeText">暂无地址</div>
    </div>
    <div class="bottom">
      <div class="btn" @click="jumpAction">添加新地址</div>
    </div>
    <y-dialog :visible="visible" class="delete-dialog">
      <div class="apply-content">
        <p>确定要删除该地址吗?</p>
      </div>
      <div class="apply-btn" slot="footer">
        <div class="btn" @click="visible = false">取消</div>
        <div class="btn sure-btn" @click="sureAction">确认</div>
      </div>
    </y-dialog>
    <div class="fixed" v-if="isOpen">
      <y-add-address :from="from" @child-close="childClose"
        :mid="mid"></y-add-address>
    </div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getQueryManageAddress, getRemoveManageAddress } from '@/api/base';
import YDialog from '@/components/dialog';
import YAddAddress from './addAddress';

export default {
  name: 'GoldAddress',
  data() {
    return {
      manageAddressList: [],
      visible: false,
      mid: '',
      isOpen: false,
      from: '',
      cuserId: getAppStatus().cuserId,
    };
  },
  components: { YDialog, YAddAddress },
  mounted() {
    // document.title = '管理收货地址';
    const { from } = this;
    this.cuserId = this.cuserId || sessionStorage.getItem('userId');
    if (!this.cuserId) {
      callAppHandler('jzUserId', null, (res) => {
        sessionStorage.setItem('userId', res);
        this.cuserId = res;
        this.initAddress();
      });
    }
    this.cuserId && this.initAddress();
  },
  computed: {
    cuserIds() {
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return 'df0fdeaa-0f72-44d1-90cd-783d6768b322';
      return getAppStatus().cuserId;
    },
    gerId() {
      const { gerId } = this.$route.query;
      return gerId;
    },
    prizeId() {
      const { prizeId } = this.$route.query;
      return prizeId;
    },
  },
  methods: {
    async initAddress() {
      let res;
      const { cuserId } = this;
      try {
        res = await getQueryManageAddress({ cuserid: cuserId });
      } catch (e) {
        console.log(e);
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      console.log(res);
      if (res.code === 1) {
        this.manageAddressList = res.results.manageAddressList || [];
        if (!this.manageAddressList.length) {
          sessionStorage.removeItem('addressList');
        }
      }
    },
    addressAction(data) {
      let arr = data.address.split('/');
      return arr[0] + '<br/>' + arr[1];
    },
    jumpAction() {
      let { query } = this.$route;
      query = { ...query, from: 'add' };
      // this.$router.push({
      //   path: 'editAddress',
      //   query,
      // });
      this.from = 'add';
      this.isOpen = true;
    },
    jumpUrl(data) {
      let data1 = JSON.stringify(data);
      sessionStorage.setItem('addressList', data1);
      const { from, gerId, prizeId } = this;
      console.log(this.$route.query);
      if (from !== 'read') {
        const { mid } = data;
        let query = from === 'record' ? { gerId } : { prizeId };
        console.log(mid, 'mid-----');
        const querys = this.$route.query;
        query = { mid, ...query, ...querys };
        this.$emit('child-open');
        // this.$router.push({
        //   path: 'order',
        //   query,
        // });
      }
    },
    async sureAction() {
      let res;
      const { mid } = this;
      try {
        res = await getRemoveManageAddress({
          mid,
        });
      } catch (e) {
        console.log(e);
        this.visible = false;
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        this.visible = false;
        this.initAddress();
      }
    },
    addressBehavior(data, status) {
      this.mid = data.mid;
      const {
        from, mid, gerId, prizeId,
      } = this;
      if (status === 'delete') {
        this.visible = true;
      } else {
        const params = this.$route.query;
        const query = {
          mid,
          r: Math.random(),
          ...params,
        };
        if (from === 'record') {
          query.gerId = gerId;
        } else if (from !== 'record' && from !== 'read') {
          query.prizeId = prizeId;
        }
        query.from = 'edit';
        this.from = 'edit';
        // this.$router.push({
        //   path: 'editAddress',
        //   query,
        // });
        this.isOpen = true;
        sessionStorage.setItem('addressList', JSON.stringify(data));
      }
    },
    childClose() {
      this.initAddress();
      this.isOpen = false;
    },
  },
};
</script>
<style scoped lang="scss">
  .address-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f6f6f6;
    padding-top: 0.1px;
    .bottom {
      width: 100%;
      height: 1.46667rem;
      position: fixed;
      left: 0;
      bottom: 0;
      background: #fff;
      -webkit-box-shadow: 0 -.10667rem .10667rem 0 rgba(0,0,0,.04);
      box-shadow: 0 -.10667rem .10667rem 0 rgba(0,0,0,.04);
      .btn {
        margin: .13333rem .37333rem;
        height: 1.17333rem;
        background: linear-gradient(95deg,#ff4a4a,#ff3636);
        border-radius: .58667rem;
        font-size: .45333rem;
        font-weight: 500;
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
  }
  .hasNoPrize {
    padding: 44.48% 0 0 0;
    margin: 0 auto;
  }
  .addressItemDiv {
  height: 100%;
  padding-bottom: 1.3rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.addressItem {
  width: 100%;
  background: #ffffff;
  margin-bottom: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid 2px transparent;
}
.addressContent {
  width: 100%;
  min-height: 2.666666rem;
  padding: 0.370666rem 0.4rem 0.266666rem 1.226666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}
.readAddress {
  padding: 0.370666rem 0.4rem 0.266666rem 0.4rem;
}
.addressContent:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.266666rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  display: inline-block;
  vertical-align: middle;
  /*width: 0.533333rem;
	height: 0.562666rem;*/
  width: 0.557rem;
  height: 0.59rem;
  /*margin-right: 0.266666rem;*/
  // background-image: url(../img/nochoose.png);
  background-size: 100% 100%;
  border: 4px solid #000;
  border-radius: 100%;
}
.readAddress:before {
  display: none;
}
.activeAddress .addressContent:before {
  // background-image: url(../img/default.png);
}
.line1 {
  overflow: hidden;
  margin-bottom: 0.266666rem;
}
.name {
  /*float: left;*/
  display: inline-block;
  vertical-align: middle;
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  margin-right: 0.8rem;
}
.tel {
  display: inline-block;
  vertical-align: middle;
  /*float: right;*/
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
}
.line2 {
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  padding-right: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.orderContainer .line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.operate {
  height: 1.066666rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-top: solid 1px #dddddd;
  overflow: hidden;
}
.default {
  float: left;
  display: none;
}

.default > span {
  line-height: 1.066666rem;
  font-size: 0.346666rem;
  letter-spacing: -0.3px;
  display: inline-block;
  vertical-align: middle;
  /*color: #ee4f4f;*/
}
/*.default>span:nth-of-type(1)>img{
	width: 100%;
	height: auto;
	display: block;
}*/
.active > span {
  color: #ee4f4f !important;
}
.default:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.426666rem;
  height: 0.450666rem;
  margin-right: 0.266666rem;
  // background-image: url(../img/nochoose.png);
  background-size: cover;
}
.active:before {
  // background-image: url(../img/default.png) !important;
}
.edit {
  float: right;
  margin-right: 0.92rem;
}
.editBefore {
  /*content: "";*/
  display: inline-block;
  vertical-align: middle;
  width: 0.426666rem;
  /*height: 0.421333rem;*/
  margin-right: 0.28rem;
}
.editBefore > img {
  width: 100%;
  height: auto;
  display: block;
}
.edit > span,
.delete > span {
  font-size: 0.346666rem;
  letter-spacing: -0.3px;
  color: #333333;
  line-height: 1.066666rem;
  display: inline-block;
  vertical-align: middle;
}
.delete {
  float: right;
}
.deleteBefore {
  /*content: "";*/
  display: inline-block;
  vertical-align: middle;
  width: 0.426666rem;
  /*height: 0.445333rem;*/
  margin-right: 0.28rem;
  /*background-image: url(../img/delete.png);*/
  /*background-size: cover;*/
}
.deleteBefore > img {
  width: 100%;
  height: auto;
  display: block;
}
.apply-content {
  padding: 1.047999rem 0 0.829333rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
  font-size: 0.426666rem;
  text-align: center;
  color: #333333;
}
.delete-dialog {
  /deep/.y-dialog {
    width: 600px;
    height: 320px;
  }
  /deep/.y-dialog__footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    .apply-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: solid 1PX#dddddd;
      .btn {
        flex: 1;
        color: #333;
        vertical-align: middle;
        font-size: 0.48rem;
        text-align: center;
        line-height: 1.48rem;
        box-sizing: border-box;
        &:nth-child(1) {
          border-right: solid 1PX#dddddd;
        }
        &.sure-btn {
          color: #ee4f4f;
        }
      }
    }
  }
}
.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
